<template>
  <b-card title="Rapor Oluştur">
    <b-form-group
      label="Rapor Adı"
      label-for="title"
    >
      <b-form-input
        id="title"
        v-model="itemData.title"
        placeholder="Rapor Adı"
      />
    </b-form-group>
    <b-form-group
      label="Plaka Listesi"
      label-for="licence_plate"
    >
      <b-form-textarea
        id="licence_plate"
        v-model="plateList"
        rows="10"
        placeholder="Plaka Listesi"
      />
    </b-form-group>
    <b-button
      variant="success"
      :disabled="!itemData.licence_plate.length || !itemData.title"
      @click="saveData"
    >
      <FeatherIcon icon="SaveIcon" />
      Oluştur
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'

export default {
  name: 'CreateReport',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      plateList: null,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['rentalExpense/itemData']
    },
  },
  watch: {
    plateList(val) {
      if (val) {
        this.itemData.licence_plate = val.split('\n')
      } else {
        this.itemData.licence_plate = []
      }
    },
  },
  methods: {
    saveData() {
      console.log('aaa')
      this.$store.dispatch('rentalExpense/saveData', this.itemData)
    },
  },
}
</script>
