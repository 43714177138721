<template>
  <b-row>
    <b-col md="12">
      <create-report />
    </b-col>
    <b-col>
      <b-card title="Geçmiş Raporlar">
        test
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CreateReport from '@/views/Reports/Rental/ExpenseReport/CreateReport.vue'

export default {
  name: 'ExpenseReport',
  components: {
    BRow,
    BCol,
    BCard,
    CreateReport,
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalExpense/dataList']
    },
    itemData() {
      return this.$store.getters['rentalExpense/itemData']
    },
    saveStatus() {
      return this.$store.getters['rentalExpense/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('rentalExpense/getDataList')
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
